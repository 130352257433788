import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePageEng from "./pages/Home/view/HomePageEng";
import Wordle from "./pages/Projects/Wordle/view/Wordle";
import SofwareVerificationPage from "./pages/University/SwVerPage/SoftwareVerification";
import "./index.css";
import FourOFour from "./pages/404/view/404";
import UniversityHub from "./pages/University/university-hub";
import HomePageIta from "./pages/Home/view/HomePageIta";

function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<HomePageEng />}></Route>
				<Route path="/it" element={<HomePageIta />}></Route>
				<Route path="/university" element={<UniversityHub />}></Route>
				<Route path="/abstract-interpreter" element={<SofwareVerificationPage />}></Route>
				<Route path="/wordle" element={<Wordle />}></Route>
				<Route path="*" element={<FourOFour />}></Route>
			</Routes>
		</Router>
	);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
